<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-model-table model-name="Vouchers" model-title="Discount Vouchers"
          :model-api="['models','Master','Vouchers']" :headers="headers" importable searchable>
          <template v-slot:modelForm="{data,rules,isCreating}">
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-text-field :rules="[rules.require()]" v-model="data.voucherName" label="Voucher Name"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-date-field v-model="data.expiredDateTime" label="วันหมดอายุ"></v-date-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="data.voucherDetail" label="รายละเอียด" auto-grow></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-table-field v-model="data.conditions" title="เงื่อนไข" item-key="productCode" :headers="conditionsHeaders" hide-toolbar show-create-pad auto-item-action>
                    <template v-slot:createPad="{data: tmplData,rules}">
                      <v-container fluid pa-0>
                        <v-row>
                          <v-col cols="8">
                            <v-master-field :rules="[rules.require()]" v-model="tmplData.productCode" 
                              :custom-api="['models','Master','Products','query']" custom-api-text="productName" 
                              custom-api-value="productCode" label="รายการสินค้า" 
                              show-code
                              key="productCode"
                            ></v-master-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field v-model="tmplData.price" label="ราคา" :rules="[rules.numeric()]"></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field v-model="tmplData.discount" label="ส่วนลด" :rules="[rules.numeric()]"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                    <template v-slot:item.productName="props">
                      <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
                    </template>
                  </v-table-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'ID',
        value: 'id',
        class: 'body-2'
      },
      {
        text: 'Name',
        value: 'voucherName',
        class: 'body-2'
      },
      {
        text: 'Expired Date',
        value: 'expiredDateTime',
        class: 'body-2'
      },
      {
        text: 'Detail',
        value: 'voucherDetail',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    conditionsHeaders: [
      {
        text: 'Product Code',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'Product Name',
        value: 'productName',
        class: 'body-2'
      },
      {
        text: 'Price',
        value: 'price',
        class: 'body-2'
      },
      {
        text: 'Discount',
        value: 'discount',
        class: 'body-2'
      },
    ],
  }),
}
</script>

<style lang="css">
</style>
